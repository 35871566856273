import React from 'react';
import styled from 'styled-components';

import CardTexts from './CardTexts';

import Button from '../common/Button';

import { ReactComponent as Box } from '../../assets/svgs/activate-pricing-box.svg';
import FunctionList from './FunctionList';
import { MEDIA_MAX_MOBILE_HORIZONTAL_MAX, MEDIA_MAX_TABLET_VERTICAL_WIDTH } from '../../constants/media';

export interface EssentialCardProps {
  onDownload: () => void;
}

function EssentialCard({ onDownload }:EssentialCardProps) {
  return (
    <Wrapper>
      <TopContents>
        <CardTexts.Type>*Temporarily paused from Nov. &apos;24</CardTexts.Type>
        <CardTexts.Price>$0*</CardTexts.Price>
        <CardTexts.Status />
        <CardTexts.Subtitle>Everyone</CardTexts.Subtitle>
        <CardTexts.Target />
        <CardTexts.Description>
          Fully packed with all the digital tools for 3D drawing & painting to try out, doodle, or create artwork.
        </CardTexts.Description>
      </TopContents>
      <ButtonArea>
        <Button onClick={onDownload}>Download</Button>
      </ButtonArea>
      <BottomContents>
        <FunctionList
          functions={[
            'View, draw, edit, & transform in 3D',
            'Import image and OBJ',
            'Export as image, video, OBJ, and GLTF',
            'Place your drawings with AR',
            'Shareable links',
            '+ Future updates',
          ]}
        />
      </BottomContents>
      <FullWidthBox />
    </Wrapper>
  );
}

export default EssentialCard;

const TopContents = styled.div`
  position: absolute;
  top: 5%;
  left: 10%;
  right: 11%;
  bottom: 37.5%;

  & > ${CardTexts.Type} {
    margin-bottom: 0.5rem;
  }

  & > ${CardTexts.Status} {
    margin-bottom: 1.25rem;
  }

  & > ${CardTexts.Subtitle} {
    margin-bottom: 1.25rem;
  }

  & > ${CardTexts.Target} {
    display: none;
  }

  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
    & > ${CardTexts.Type} {
      margin-bottom: 1rem;
    }

    & > ${CardTexts.Status} {
      margin-bottom: 1.75rem;
    }

    & > ${CardTexts.Subtitle} {
      margin-bottom: 1.75rem;
    }
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    text-align: center;

    & > ${CardTexts.Subtitle}, & > ${CardTexts.Description} {
      display: none;
    }

    & > ${CardTexts.Target} {
      display: block;
    }
  }
`;

const ButtonArea = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 38.5%;

  & > button {
    width: 208px;
    height: 64px;
    font-size: 1.125rem;
    margin: auto;
  }

  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
    & > button {
      width: 320px;
      height: 95px;
      font-size: 1.875rem;
    }
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    & > button {
      width: 208px;
      height: 64px;
      font-size: 1.25rem;
    }
  }
`;

const BottomContents = styled.div`
  position: absolute;
  top: 71.5%;
  left: 10%;
  right: 11%;
  bottom: 10%;

  & > ul {
    margin-bottom: 1.5rem;
    & > li::before {
      color: #A7A7B3;
    }
  }
`;

const FullWidthBox = styled(Box)`
  width: 100%;
  height: 100%;

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    display: none;
  }
`;

const Wrapper = styled.article`
  position: relative;
  display: inline-block;
  width: 327px;
  height: 571px;

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    display: inline-flex;
    flex-direction: column;

    & > div {
      position: relative;
      top: initial;
      left: initial;
      right: initial;
      bottom: initial;
    }

    ${BottomContents} {
      margin-bottom: 3rem;
    }

    ${ButtonArea} {
      order: 1;
    }
  }
`;
