import React from 'react';
import styled from 'styled-components';
import { MEDIA_MAX_MOBILE_HORIZONTAL_MAX } from '../../constants/media';
import Heading3 from '../common/Heading3';

export interface DownloadGuideProps {
  className?: string;
}

function DownloadGuide({ className }:DownloadGuideProps) {
  return (
    <Section className={className}>
      <Heading3>Download Feather*</Heading3>
      <Text>
        *You can freely use the current version, but
        <br />
        <b>in November 2024, it will be temporarily paused</b>
        <br />
        <b>and the new Pro version will launch.</b>
        <br />
        <br />
        For the best experience, please use a device with both PEN & TOUCH input.
      </Text>
      <EgText>(e.g. iPad with Apple Pencil or Galaxy Tab with S Pen)</EgText>
    </Section>
  );
}

export default DownloadGuide;

const Section = styled.section`
  text-align: center;
  line-height: 170%;

  & > ${Heading3} {
    margin-bottom: 2rem;
  }
`;

const Text = styled.p`
  font-size: 1.1rem;
  padding: 0 3rem;
`;

const Required = styled(Text)`
  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    margin-bottom: 1.5rem;
    & > span {
      display: block;
    }
  }
`;

const EgText = styled(Text)`
  color: #7B7B8B;
`;
