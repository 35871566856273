import React from 'react';
import styled from 'styled-components';

import CardTexts from './CardTexts';

import Button from '../common/Button';

// import { ReactComponent as Box } from '../../assets/svgs/deactivate-pricing-box.svg';
import { ReactComponent as Box } from '../../assets/svgs/activate-pricing-box.svg';
import FunctionList from './FunctionList';
import { MEDIA_MAX_MOBILE_HORIZONTAL_MAX, MEDIA_MAX_TABLET_VERTICAL_WIDTH } from '../../constants/media';

export interface PlusCardProps {
  onSubscribe: () => void;
}

function PlusCard({ onSubscribe }:PlusCardProps) {
  return (
    <Wrapper>
      <TopContents>
        <CardTexts.Type>Pro</CardTexts.Type>
        <CardTexts.Price>Out in Nov. &apos;24</CardTexts.Price>
        <CardTexts.Status />
        <CardTexts.Subtitle>Professionals</CardTexts.Subtitle>
        <CardTexts.Target />
        <CardTexts.Description>
          Enhanced with features for enthusiasts who want to use Feather in their creative workflow.
        </CardTexts.Description>
      </TopContents>
      <ButtonArea>
        <Button onClick={onSubscribe}>Get notified</Button>
      </ButtonArea>
      <BottomContents>
        <CardTexts.Description>
          <b>Everything in Free, plus</b>
        </CardTexts.Description>
        <FunctionList
          functions={[
            'Offline mode',
            'Advanced import/export',
            'More brush types',
            'Post-processing tools',
            '+ Future updates',
          ]}
        />
      </BottomContents>
      <FullWidthBox />
    </Wrapper>
  );
}

export default PlusCard;

const TopContents = styled.div`
  position: absolute;
  z-index: 1;
  top: 5%;
  left: 10%;
  right: 11%;
  bottom: 37.5%;

  & > ${CardTexts.Type} {
    margin-bottom: 0.5rem;
  }

  & > ${CardTexts.Status} {
    margin-bottom: 1.25rem;
  }

  & > ${CardTexts.Subtitle} {
    margin-bottom: 1.25rem;
  }

  & > ${CardTexts.Target} {
    display: none;
  }

  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
    & > ${CardTexts.Type} {
      margin-bottom: 1rem;
    }

    & > ${CardTexts.Status} {
      margin-bottom: 1.75rem;
    }

    & > ${CardTexts.Subtitle} {
      margin-bottom: 1.75rem;
    }
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    text-align: center;

    & > ${CardTexts.Subtitle}, & > ${CardTexts.Description} {
      display: none;
    }

    & > ${CardTexts.Target} {
      display: block;
    }
  }
`;

const ButtonArea = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 38.5%;

  & > button {
    width: 208px;
    height: 64px;
    font-size: 1.125rem;
    margin: auto;
    // color: ${({ theme }) => theme.color};

    // & > svg {
    //   fill: #D9D9E3;

    //   & path[class='background'] {
    //     fill: ${({ theme }) => theme.color};
    //   }
    // }
  }

  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
    & > button {
      width: 320px;
      height: 95px;
      font-size: 1.875rem;
    }
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    & > button {
      width: 208px;
      height: 64px;
      font-size: 1.25rem;
    }
  }
`;

const BottomContents = styled.div`
  position: absolute;
  z-index: 1;
  top: 71%;
  left: 10%;
  right: 11%;
  bottom: 10%;

  & > ul {
    margin-bottom: 1.5rem;
    & > li::before {
      color: #A7A7B3;
    }
  }

  & > ${CardTexts.Description} {
    margin-bottom: 1.5rem;
  }
`;

const FullWidthBox = styled(Box)`
  width: 100%;
  height: 100%;

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    display: none;
  }
`;

const Wrapper = styled.article`
  position: relative;
  display: inline-block;
  width: 327px;
  height: 571px;

  // & > svg {
  //   opacity: 0.6;
  // }

  // & > div > *:not(button) {
  //   opacity: 0.6;
  // }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    display: inline-flex;
    flex-direction: column;

    & > div {
      position: relative;
      top: initial;
      left: initial;
      right: initial;
      bottom: initial;
    }

    ${BottomContents} {
      margin-bottom: 3rem;
    }

    ${ButtonArea} {
      order: 1;
    }
  }
`;
